import React from 'react';
import LogoNordWorks from '../../assets/NordWorks_Logo.svg';
import './about.css';


const About = () => {
  return (
    <div className="nordworks_about">
        <a className="anchor-about" id="nordworks_about">About</a>
        <div className="nordworks_about-logo">
            <img src={LogoNordWorks} alt="NordWorks Logo"/>
        </div>
        <div className="nordworks_about-panel">
            <div className="nordworks_about-panel-heading">
                <h1>O NordWorks</h1>
            </div>
            <div className="nordworks_about-panel-description">
                <p>
                    NordWorks to agencja pośrednictwa pracy, będąca przedstawicielem szwedzkiej firmy <a href="https://expanderamera.se/" target="_blank" rel="noreferrer">ExpanderaMera</a>.
                    <br /><br />
                    Agencja pracy <a href="https://expanderamera.se/" target="_blank" rel="noreferrer">ExpanderaMera</a> jest odnoszącą sukcesy i dobrze funkcjonującą, jedną z największych
                    szwedzkich firm rekrutacyjnych w branży budowlanej. Specjalizuje się w zatrudnianiu wykwalifikowanych
                    pracowników w budownictwie.
                    <br /><br />
                    <a href="https://expanderamera.se/" target="_blank" rel="noreferrer">ExpanderaMera</a> tworzy miejsca pracy, w których przyszli pracownicy z Polski mogą znaleźć pracę na
                    uczciwych, jasnych i prostych zasadach, w oparciu o legalne umowy na warunkach szwedzkich. Udziela
                    wsparcia na każdym etapie rekrutacji oraz przy wszystkich koniecznych do zatrudnienia formalnościach.
                    Współpraca oparta jest na zaufaniu i wiarygodnych pracodawcach na terenach całej Szwecji.
                    <br /><br />
                    To właśnie dzięki temu oferujemy konkurencyjne wynagrodzenia, świadczenia dodatkowe, ubrania robocze
                    i wiele innych.
                    <br /><br />
                    Jeżeli jesteś zainteresowany pracą w budownictwie na terenach Szwecji oraz masz co najmniej 5 letnie
                    doświadczenie to skontaktuj się z nami!
                </p>
            </div>
        </div>
    </div>
  )
}

export default About