import React from 'react'

import { Header, Infocards, About, Offer, Partners, Forms, Footer } from './containers';
import { Navbar } from './components';

import './App.css';

const App = () => {
  return (
    <div className="App">
        <Header />
        <Infocards />
        <About />
        <Offer />
        <Partners />
        <Forms />
        <Footer />
    </div>
  )
}

export default App