import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/fontawesome-free-regular';
import { faSquareFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import NordWorksLogo from '../../assets/NordWorks_Logo.svg';
import './footer.css';


const Footer = () => {
  return (
    <div className="nordworks__footer">
      <div className="nordworks__footer-container">
        <div className="nordworks__footer-contactinfo">
          <h1>Kontakt</h1>
          <h3><span><FontAwesomeIcon icon={faLocationDot}/></span>Adres</h3>
          <p>Chopina 28 / pok. 120,<br />83-000 Pruszcz Gdański</p>
          <h3><span><FontAwesomeIcon icon={faEnvelope}/></span>E-mail</h3>
          <a href="mailto:praca@nord-works.com" target="_blank" rel="noreferrer"><p className="p-mail">praca@nord-works.com</p></a>
          <h3><span><FontAwesomeIcon icon={faPhone} /></span>Telefony</h3>
          <a href="tel:+48732937134" target="_blank" rel="noreferrer"><p className="p-tel">+48 732 937 134</p></a>
          <a href="tel:+48664996213" target="_blank" rel="noreferrer"><p className="p-tel">+48 664 996 213</p></a>
        </div>
        <div className="nordworks__footer-links">
          <h1>Nawigacja</h1>
          <a href="/#nordworks_home"><p>Strona główna</p></a>
          <a href="/#nordworks_about"><p>O nas</p></a>
          <a href="/#nordworks_offer"><p>Oferty pracy</p></a>
          <a href="/#nordworks_partners"><p>Partnerzy</p></a>
          <a href="/#nordworks_forms"><p>Kontakt</p></a>
        </div>
      </div>
      <div className="nordworks__footer-copyright">
        <div className="nordworks__footer-copyright-nordworks">
          <a href="/#nordworks_home"><button type="button"><img src={NordWorksLogo} alt="NordWorks Logo"/></button></a>
        </div>
        <div className="nordworks__footer-copyright-design">
          <h2>Copyright&nbsp;<FontAwesomeIcon icon={faCopyright} />&nbsp;2023 Jakub Jabłoński. All rights reserved.</h2>
        </div>
        <div className="nordworks__footer-copyright-sm">
          <a className="nordworks__footer-copyright-sm_fb" href="https://www.facebook.com/nordworkscom" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faSquareFacebook} /></a>
          <a className="nordworks__footer-copyright-sm_ig" href="https://www.instagram.com/nordworks_com/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
          <a className="nordworks__footer-copyright-sm_ln" href="https://www.linkedin.com/company/nordworks/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
        </div>
      </div>
    </div>
  )
}

export default Footer