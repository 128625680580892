import React, { useState } from 'react';
import { BurgerClose } from 'react-burger-icons';
import LogoNordWorks from '../../assets/NordWorks_Logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import './navbar.css';

const Menu = () => (

    <>
        <p id="home"><a href="/#nordworks_home">Strona główna</a><span id="line1" /></p>
        <p id="about"><a href="/#nordworks_about">O nas</a><span id="line2" /></p>
        <p id="offer"><a href="/#nordworks_offer">Oferty pracy</a><span id="line3" /></p>
        <p id="partners"><a href="/#nordworks_partners">Partnerzy</a><span id="line4" /></p>
        <p id="contact"><a href="/#nordworks_forms">Kontakt</a><span id="line5" /></p>
    </>
)

const Navbar = () => {
    const [isClosed, setIsClosed] = useState(false);

  return (
    <div>
    <div className="nordworks__navbar">
        <div className="nordworks__navbar-logo">
            <a href="/#nordworks_home"><img id="nordworks-logo" src={LogoNordWorks} alt="NordWorks Logo"/></a>
        </div>
        <div className="nordworks__navbar-links">
            <Menu />
        </div>
        <div className="nordworks__navbar-menubtn">
            <button className="nordworks__navbar-menubtn_button" onClick={() => setIsClosed(!isClosed)} style={{width: "2rem", height: "2rem", display: "grid", placeItems: "center",}}>
                <BurgerClose isClosed={isClosed} />
         </button>
        </div>
        <div className="nordworks__navbar-socialmedia">
            <a className="nordworks__navbar-socialmedia-fb" href="https://www.facebook.com/nordworkscom" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
            <a className="nordworks__navbar-socialmedia-ig" href="https://www.instagram.com/nordworks_com/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
            <a className="nordworks__navbar-socialmedia-fb" href="https://www.linkedin.com/company/nordworks/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
        </div>
    </div>
    <div>
        {isClosed && (
            <div className="nordworks__navbar-menu scale-up-ver-top">
                    <p id="home" onClick={() => setIsClosed(!isClosed)}><a href="/#nordworks_home">Strona główna</a><span id="line1" /></p>
                    <p id="about" onClick={() => setIsClosed(!isClosed)}><a href="/#nordworks_about">O nas</a><span id="line2" /></p>
                    <p id="offer" onClick={() => setIsClosed(!isClosed)}><a href="/#nordworks_offer">Oferty pracy</a><span id="line3" /></p>
                    <p id="partners" onClick={() => setIsClosed(!isClosed)}><a href="/#nordworks_partners">Partnerzy</a><span id="line4" /></p>
                    <p id="contact" onClick={() => setIsClosed(!isClosed)}><a href="/#nordworks_forms">Kontakt</a><span id="line5" /></p>
                <div className="nordworks__navbar-menu-socialmedia">
                    <a className="nordworks__navbar-socialmedia-fb" href="https://www.facebook.com/nordworkscom" target="_blank" rel="noreferrer" onClick={() => setIsClosed(!isClosed)}><FontAwesomeIcon icon={faFacebook} /></a>
                    <a className="nordworks__navbar-socialmedia-ig" href="https://www.instagram.com/nordworks_com/" target="_blank" rel="noreferrer" onClick={() => setIsClosed(!isClosed)}><FontAwesomeIcon icon={faInstagram} /></a>
                    <a className="nordworks__navbar-socialmedia-fb" href="https://www.linkedin.com/company/nordworks/" target="_blank" rel="noreferrer" onClick={() => setIsClosed(!isClosed)}><FontAwesomeIcon icon={faLinkedin} /></a>
                </div>
            </div>
        )}
    </div>
    </div>
  )
}

export default Navbar