import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faFileCircleCheck, faHandshakeAngle, faListCheck } from "@fortawesome/free-solid-svg-icons";
import './infocards.css';

const Infocards = () => {
  return (
    <div className="nordworks_infocards">
        <div className="nordworks_infocards-info">
            <div className="info-top">   
                <p className="nordworks_infocards-info-icon"><FontAwesomeIcon icon={faUserCheck}></FontAwesomeIcon></p>
                <h1>Sprawdzony<br />Pracodawca</h1>
            </div> 
            <div className="info-bottom">
                <p className="nordworks_infocards-info-description">Współpracujemy z wiarygodnym pracodawcą na terenie Szwecji jakim jest ExpanderaMera.</p>
            </div>
        </div>
        <div className="nordworks_infocards-info">
            <div className="info-top">
                <p className="nordworks_infocards-info-icon"><FontAwesomeIcon icon={faFileCircleCheck}></FontAwesomeIcon></p>
                <h1>Legalna<br />Umowa</h1>
            </div>
            <div className="info-bottom">
                <p className="nordworks_infocards-info-description">Oferujemy legalne zatrudnienie z umową o pracę na warunkach szwedzkich.</p>
            </div>
        </div>
        <div className="nordworks_infocards-info">
            <div className="info-top">
                <p className="nordworks_infocards-info-icon"><FontAwesomeIcon icon={faHandshakeAngle}></FontAwesomeIcon></p>
                <h1>Wsparcie<br />Rekrutacyjne</h1>
            </div>
            <div className="info-bottom">
                <p className="nordworks_infocards-info-description">Pomagamy ze wszystkimi formalnościami związanymi z podjęciem pracy.</p>
            </div>
        </div>
        <div className="nordworks_infocards-info">
            <div className="info-top">
                <p className="nordworks_infocards-info-icon"><FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon></p>
                <h1>Przejrzyste<br />Warunki</h1>
            </div>
            <div className="info-bottom">
                <p className="nordworks_infocards-info-description">U nas otrzymasz oferty tylko z jasnymi warunkami wykonywanej pracy.</p>
            </div>
        </div>
    </div>
  )
}

export default Infocards