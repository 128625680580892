import React from 'react';
import ExpanderaMera from '../../assets/expandera_mera_logo.png'

import './partners.css'

const Partners = () => {
  return (
    <div className="nordworks_partnership">
        <a className="anchor-partners" id="nordworks_partners">Partners</a>
        <div className="nordworks_partnership-heading">
            <h1>Partner</h1>
        </div>
        <div className="nordworks_partnership-content">
            <div className="partnership-content-brand">
                <a href="https://expanderamera.se/" target="_blank" rel="noreferrer"><img src={ ExpanderaMera } alt="ExpanderaMera" /></a>
            </div>
            {/*<div className="partnership-content-brand">
                <img src={ ExpanderaMera } alt="ExpanderaMera" />
            </div>
            <div className="partnership-content-brand">
                <img src={ ExpanderaMera } alt="ExpanderaMera" />
            </div>
            <div className="partnership-content-brand">
                <img src={ ExpanderaMera } alt="ExpanderaMera" />
            </div>*/}
        </div>
    </div>
    
  )
}

export default Partners