import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer, faTrowel, faPersonDigging } from "@fortawesome/free-solid-svg-icons";
import offer1 from '../../assets/offer_1.jpg';
import offer2 from '../../assets/offer_2.jpg';
import offer3 from '../../assets/offer_3.jpg';
import offer4 from '../../assets/offer_4.jpg';
import './offer.css';

const Offer = () => {
  return (
    <div className="nordworks_offer">
        <a className="anchor-offer" id="nordworks_offer">Offer</a>
        <div className="nordworks_offer-heading">
            <h1>Oferty pracy</h1>
        </div>
        <div className="nordworks_offer-panel">
            <div className="nordworks_offer-panel-container">
                <div className="offer-left">
                    <img src={offer1} alt="Oferta stolarz" />
                </div>
                <div className="offer-center">    
                    <div className="offer-title">
                        <p className="offer-title-icon"><FontAwesomeIcon icon={faHammer}></FontAwesomeIcon></p>
                        <h2>Stolarz / Cieśla budowlany</h2>
                    </div>
                    <div className="offer-description">
                        <p>Poszukujemy stolarzy z min. 6 letnim udokumentowanym doświadczeniem do prac stolarskich w nowym budownictwie.</p>
                    </div>
                </div>
                <div className="offer-right">
                    <a href="/oferta-stolarz"><button>Sprawdź</button></a>
                </div>
            </div>
            <div className="nordworks_offer-panel-container">
                <div className="offer-left">
                    <img src={offer2} alt="Oferta zbrojarz" />
                </div>
                <div className="offer-center">    
                    <div className="offer-title">
                        <p className="offer-title-icon"><FontAwesomeIcon icon={faTrowel}></FontAwesomeIcon></p>
                        <h2>Zbrojarz / Betoniarz</h2>
                    </div>
                    <div className="offer-description">
                        <p>Poszukujemy zbrojarzy z min. 6 letnim udokumentowanym doświadczeniem.</p>
                    </div>
                </div>
                <div className="offer-right">
                    <a href="/oferta-zbrojarz"><button>Sprawdź</button></a>
                </div>
            </div>
            {/*
            <div className="nordworks_offer-panel-container">
                <div className="offer-left">
                    <img src={offer3} alt="Oferta pracownik robót ziemnych" />
                </div>
                <div className="offer-center">    
                    <div className="offer-title">
                        <p className="offer-title-icon"><FontAwesomeIcon icon={faPersonDigging}></FontAwesomeIcon></p>
                        <h2>Pracownik robót ziemnych</h2>
                    </div>
                    <div className="offer-description">
                        <p>Poszukujemy 3 pracowników robót ziemnych, doświadczenie niewymagane.</p>
                    </div>
                </div>
                <div className="offer-right">
                    <a href="https://www.pracuj.pl/" target="_blank" rel="noreferrer"><button>Sprawdź</button></a>
                </div>
            </div>
            */}
        </div>
    </div>
  )
}

export default Offer