import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import { Navbar } from './components';
import { OfertaZbrojarz, OfertaStolarz } from './multisite';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <div className="App">
            <Navbar />
            <Routes>
                <Route path="" element={<React.StrictMode><App /></React.StrictMode>} />
                <Route path="oferta-zbrojarz" element={<OfertaZbrojarz />} />
                <Route path="oferta-stolarz" element={<OfertaStolarz />} />
            </Routes>
        </div>
    </BrowserRouter>
);