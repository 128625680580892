import React from 'react';
import { Emailform } from '../../components/emailform/Emailform';

import './forms.css'

const Forms = () => {
  return (
    <div className="nordworks_forms">
      <a className="anchor-forms" id="nordworks_forms">Forms</a>
        <div className="nordworks_forms-heading">
            <h1>Kontakt</h1>
        </div>
        <div className="nordworks_forms-content">
            <Emailform />
        </div>
    </div>
    
  )
}

export default Forms