import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import './emailform.css';

export const Emailform = () => {
  const form = useRef();
  const [username, setUsername] = useState();
  const [usersurname, setUsersurname] = useState();
  const [useremail, setUseremail] = useState();
  const [usermessage, setUsermessage] = useState();
  const [windowwidth, setWindowwidth] = useState("large");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d3rnt0r', 'template_84vumgg', form.current, '9900ggfp0u4fYBpjQ')
      .then((result) => {
          e.target.reset();
          setUsername("");
          setUsersurname("");
          setUseremail("");
          setUsermessage("");
      }, (error) => {
          console.log(error.text);
      });
  };

  const customTheme = (outerTheme) =>
  createTheme({
    typography: {
      allVariants: {
        color: '#fff',
        fontFamily: 'var(--font-family)',
        fontWeight: '500',
      },
      body1: {
        '@media (max-width: 1200px)': {
          fontSize: '0.95rem',
        },
        '@media (max-width: 1024px)': {
          fontSize: '0.85rem',
        },
        '@media (max-width: 768px)': {
          fontSize: '0.75rem',
        },
        fontSize: '1rem',
      },
      caption: {
        '@media (max-width: 768px)': {
          fontSize: '0.6rem',
        },
        fontSize: '0.75rem',
      },
    },
    palette: {
      mode: outerTheme.palette.mode,
      text: {
        primary: '#fdbe34',
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#f2f7ff',
            '--TextField-brandBorderHoverColor': '#fdbe34',
            '--TextField-brandBorderFocusedColor': 'var(--color-yellow)',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: '#fdbe34',
            color: '#2f3032',
            fontWeight: '800',
            '@media (max-width: 1200px)': {
              fontSize: '1.05rem',
            },
            '@media (max-width: 1024px)': {
              fontSize: '0.95rem',
            },
            '@media (max-width: 768px)': {
              fontSize: '0.85rem',
            },
            fontSize: '1.1rem',
          },
          root: {
            '&:disabled': {
              cursor: 'not-allowed',
              pointerEvents: 'all !important',
              backgroundColor: '#f2f7ff',
            },
            '&:hover:not(.Mui-disabled)': {
              backgroundColor: "rgba(253, 190, 52, 0.7)",
            },
          },
        },
      },
    },
  });

    const handleResize = () => {
      const width = window.innerWidth;
      if(width <= 900) {
        setWindowwidth("small");
      }
      else {
        setWindowwidth("large");
      }
    }
    useEffect(() => {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const outerTheme = useTheme();

  return (
    <form id="mailform" ref={form} onSubmit={sendEmail}>
      
      <ThemeProvider theme={customTheme(outerTheme)}>
        <TextField
          size={windowwidth}
          value={username}
          helperText={(username === "") && "Niepoprawna wartość"}
          onChange={(e) => setUsername(e.target.value)}
          error={username === ""}
          required
          fullWidth
          id="outlined-basic-name"
          label="Imię"
          variant="outlined"
          name="user_name" />
        <TextField
          size={windowwidth}
          value={usersurname}
          helperText={(usersurname === "") && "Niepoprawna wartość"}
          onChange={(e) => setUsersurname(e.target.value)}
          error={usersurname === ""}
          required
          fullWidth
          id="outlined-basic-surname"
          label="Nazwisko"
          variant="outlined"
          name="user_surname" />
        <TextField
          size={windowwidth}
          value={useremail}
          helperText={(useremail === "") && "Niepoprawna wartość"}
          onChange={(e) => setUseremail(e.target.value)}
          error={useremail === ""}
          required
          fullWidth
          id="outlined-basic-email"
          label="E-mail"
          variant="outlined"
          name="user_email" />
        <TextField
          size={windowwidth}
          value={usermessage}
          helperText={(usermessage === "") && "Niepoprawna wartość"}
          onChange={(e) => setUsermessage(e.target.value)}
          error={usermessage === ""}
          required
          fullWidth
          multiline
          rows={10}
          id="outlined-basic-message"
          label="Wiadomość"
          variant="outlined"
          name="user_message" />
        <Button
          disabled={(username === "") || (usersurname === "") || (useremail === "") || (usermessage === "") || (username === undefined) || (usersurname === undefined) || (useremail === undefined) || (usermessage === undefined)}
          onClick={() => {alert('Wiadomość wysłana!')}}
          size={windowwidth}
          endIcon={<SendIcon />}
          variant="contained"
          type="submit"
          value="Send">Wyślij</Button>
      </ThemeProvider>
    </form>
  );
};

export default Emailform