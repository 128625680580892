import React from 'react';
import { Footer } from '../../containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer, faTrowel, faPersonDigging, faHandHoldingDollar, faLocationDot, faFileCircleCheck, faHelmetSafety, faClock, faPhoneVolume, faBusinessTime, faBuilding, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { faSquareCheck, faSquareCaretRight, faPenToSquare, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import offer1 from '../../assets/offer_1.jpg';
import offer2 from '../../assets/offer_2.jpg';

import './ofertastolarz.css';

const OfertaStolarz = () => {
  return (
    <div className="ofertastolarz">
        <div className="ofertastolarz__header">
            <div className="ofertastolarz-left">
                    <img src={offer1} alt="Oferta zbrojarz" />
            </div>
            <div className="ofertastolarz-center">    
                <div className="ofertastolarz-title">
                    <p className="ofertastolarz-title-icon"><FontAwesomeIcon icon={faHammer}></FontAwesomeIcon></p>
                    <h2>Stolarz / Cieśla budowlany</h2>
                </div>
            </div>
            <div className="ofertastolarz-right">
                <div className="ofertastolarz-right_icon">
                    <p className="ofertastolarz-right-icon_dolar"><FontAwesomeIcon icon={faHandHoldingDollar}></FontAwesomeIcon></p>
                </div>
                <div className="ofertastolarz-right_zarobki">
                    <h1>10 000 - 20 000 PLN</h1>
                    <h2>brutto / miesięcznie</h2>
                </div>
            </div>
        </div>
        <div className="ofertastolarz__panel1">
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Szwecja</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faFileCircleCheck}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Umowa o pracę</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faHelmetSafety}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Pracownik fizyczny</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faClock}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Praca od zaraz</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faPhoneVolume}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Rekrutacja zdalna</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faBusinessTime}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Pełny etat</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Praca stacjonarna</p>
                </div>
            </div>
            <div className="panel1_info">
                <div className="panel1_info-icon">
                    <p className="panel1_info-icon_ico"><FontAwesomeIcon icon={faUsersLine}></FontAwesomeIcon></p>
                </div>
                <div className="panel1_info-description">
                    <p>Szukamy wielu kandydatów</p>
                </div>
            </div>
        </div>
        <div className="ofertastolarz__panel2">
            <div className="panel2_info">
                <div className="panel2_info-left">
                    <h1>Praca zmianowa:</h1>
                </div>
                <div className="panel2_info-right">
                    <h2>nie</h2>
                </div>
            </div>
            <div className="panel2_info">
                <div className="panel2_info-left">
                    <h1>Dni pracy:</h1>
                </div>
                <div className="panel2_info-right">
                    <h2>dni robocze (poniedziałek - piątek), sobota</h2>
                </div>
            </div>
            <div className="panel2_info">
                <div className="panel2_info-left">
                    <h1>Godziny pracy:</h1>
                </div>
                <div className="panel2_info-right">
                    <h2>07:00 - 16:00, w zależności od projektu</h2>
                </div>
            </div>
            <div className="panel2_info">
                <div className="panel2_info-left">
                    <h1>Praca w godzinach nocnych:</h1>
                </div>
                <div className="panel2_info-right">
                    <h2>nie</h2>
                </div>
            </div>
            <div className="panel2_info">
                <div className="panel2_info-left">
                    <h1>Tryb wypłaty:</h1>
                </div>
                <div className="panel2_info-right">
                    <h2>miesięczna</h2>
                </div>
            </div>
            <div className="panel2_info">
                <div className="panel2_info-left">
                    <h1>System wynagrodzeń:</h1>
                </div>
                <div className="panel2_info-right">
                    <h2>godzinowy + premia za wyniki</h2>
                </div>
            </div>
        </div>
        <div className="ofertastolarz__panel3">
            <div className="panel3_title">
                <h1>Zakres obowiązków</h1>
            </div>
            <div className="panel3_description">
                <div className="panel3_desc_info">
                    <p className="panel3_desc-icon"><FontAwesomeIcon icon={faSquareCheck}></FontAwesomeIcon></p><h2>Projekty na terytorium całej Szwecji,</h2>
                </div>
                <div className="panel3_desc_info">
                    <p className="panel3_desc-icon"><FontAwesomeIcon icon={faSquareCheck}></FontAwesomeIcon></p><h2>Praca w zespole doświadczonych stolarzy,</h2>
                </div>
                <div className="panel3_desc_info">
                    <p className="panel3_desc-icon"><FontAwesomeIcon icon={faSquareCheck}></FontAwesomeIcon></p><h2>Wykonywanie konstrukcji / montaże okien, drzwi / montaże kuchni - na podstawie rysunków,</h2>
                </div>
                <div className="panel3_desc_info">
                    <p className="panel3_desc-icon"><FontAwesomeIcon icon={faSquareCheck}></FontAwesomeIcon></p><h2>Realizowanie planów zgodnie z harmonogramem.</h2>
                </div>
            </div>
        </div>
        <div className="ofertastolarz__panel4">
            <div className="panel4_title">
                <h1>Wymagania</h1>
            </div>
            <div className="panel4_description">
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Dyspozycyjność,</h2>
                </div>
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Doświadczenie na podobnym stanowisku min. 6 lat - poparte świadectwami pracy,</h2>
                </div>
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Doświadczenie w nowym budownictwie,</h2>
                </div>
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Samodzielność na placu budowy,</h2>
                </div>
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Czytanie szwedzkich rysunków / projektów,</h2>
                </div>
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Znajomość języka angielskiego lub szwedzkiego w stopniu komunikatywnym,</h2>
                </div>
                <div className="panel4_desc_info">
                    <p className="panel4_desc-icon"><FontAwesomeIcon icon={faSquareCaretRight}></FontAwesomeIcon></p><h2>Dużym plusem jest doświadczenie ze Szwecji / Norwegii lub Finlandii.</h2>
                </div>
            </div>
        </div>
        <div className="ofertastolarz__panel5">
            <div className="panel5_title">
                <h1>Oferujemy</h1>
            </div>
            <div className="panel5_description">
                <div className="panel5_desc_info">
                    <p className="panel5_desc-icon"><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon></p><h2>Umowę o pracę,</h2>
                </div>
                <div className="panel5_desc_info">
                    <p className="panel5_desc-icon"><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon></p><h2>Ubrania robocze,</h2>
                </div>
                <div className="panel5_desc_info">
                    <p className="panel5_desc-icon"><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon></p><h2>Stawkę godzinową w zależności od doświadczenia i projektu,</h2>
                </div>
                <div className="panel5_desc_info">
                    <p className="panel5_desc-icon"><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon></p><h2>Gwarancja 40h pracy w tygodniu.</h2>
                </div>
            </div>
        </div>
        {/*
        <div className="ofertastolarz__panel6">
            <div className="panel6_title">
                <h1>Benefity</h1>
            </div>
            <div className="panel6_description">
                <div className="panel6_desc_info">
                    <p className="panel6_desc-icon"><FontAwesomeIcon icon={faSquarePlus}></FontAwesomeIcon></p><h2>Mieszkanie służbowe.</h2>
                </div>
            </div>
        </div>
        */}
        <Footer />
    </div>
  )
}

export default OfertaStolarz