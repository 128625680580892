import React from 'react';
import BackgroundNordWorks from '../../assets/NordWorks_background.jpg';

import './header.css';

const Header = () => {
  return (
    <div id="home" className="nordworks__header">
      <a className="anchor-home" id="nordworks_home">Home</a>
        <div className="nordworks__header-content">
          <img src={BackgroundNordWorks} alt="NordWorks Background" />
        </div>
    </div>
  )
}

export default Header